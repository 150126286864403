const API={
    insert:"/classification/insert",//添加信息分类get
    list:"/classification/list",//分类列表get
    editclass:"/classification/edit",//分类编辑get
    deleteclass:"/classification/delete",//分类删除get
    newsinsert:"/news/insert",//添加文章post
    newslist:"/news/list",//文章列表post
    newsdetail:"/news/detail",//文章详情get
    newsedit:"/news/edit",//文章编辑post
    newsdel:"/news/del",//文章删除post
}

export default API