<template>
    <div>
        <div class="wz">
            <div style="padding-top:4px;">
                <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>编辑信息</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <el-button @click="$router.back(-1)" icon="el-icon-back" size="small" style="float:right; margin-top:-25px;">返回</el-button>
        </div>
        <div class="box">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="文章名称">
                    <el-input v-model="form.title" style="width:400px;"></el-input>
                </el-form-item>
                <el-form-item label="分类">
                    <el-select v-model="form.classId" placeholder="请选择分类">
                    <el-option v-for="classitem in classlist" :label="classitem.className" :value="classitem.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="地区" prop="holdField" v-show="userInfo.orgId==0">
                    <el-select v-model="form.orgId" placeholder="请选地区">
                        <el-option
                        v-for="item in orglist"
                        :key="item.orgId"
                        :label="item.districtName"
                        :value="item.orgId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="来源">
                     <el-input v-model="form.source" style="width:400px;"></el-input>
                </el-form-item>
                <el-form-item label="封面图">
                    <upload-img :modeldata="form" @changeurl="" style="margin-top:15px;"></upload-img>
                </el-form-item>
                <el-form-item label="内容">
                    <div class="editor-container">
      <Uediter style="overflow:auto;height:650px;" v-show="allload" :defaultMsg="defaultMsg" :config="config" ref="ue"></Uediter>
    </div>
                    <!-- <tinymce v-model="form.articleContent" :height="400" /> -->
                    <!-- <quill-editor 
                        v-model="form.articleContent" 
                        ref="myQuillEditor" 
                        :options="editorOption" 
                        @blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
                        @change="onEditorChange($event)" class="quillEditor">
                    </quill-editor>  -->
                </el-form-item>
                <el-form-item label="" style="margin-top:80px;">
                    <el-button type="primary" @click="editArticle">保存</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import Uediter from '@/components/ue.vue';

import apiurl from '@/api/information'
import _api from '@/api/index'
export default {
    components: { Uediter },
    data(){
        return{
            userInfo:JSON.parse(window.localStorage.getItem("userInfo")),
            orglist:JSON.parse(localStorage.getItem("org")),
            allload:false,
            config: {
            initialFrameWidth: 1000,
            initialFrameHeight: 400
        },
            defaultMsg: '',
            user:null,
            form:{
                id:"",
                title:"",
                classId:"",
                source:"",
                url:"",
                articleContent:"",
                author:"",
                orgId:null,
            },
            classlist:[],
            editorOption:{}
        }
    },
    created(){
        this.getClass()
        this.user=JSON.parse(localStorage.getItem("userInfo"))
        this.form.id=this.$route.query.id
        //console.log(this.form.id)
        this.getDetail()
    },
    methods:{
        getDetail(){
            _api.get(apiurl.newsdetail,{id:this.form.id}).then(res=>{
                console.log(res)
                if(res.success){
                    this.form.title=res.data.title
                    this.form.classId=res.data.classId
                    this.form.source=res.data.source
                    this.form.url=res.data.url
                    this.form.orgId=res.data.orgId
                    this.form.articleContent=res.data.articleContent
                    this.form.author=res.data.author
                    console.log(this.$refs.ue.setUEContent)
                    console.log(222222)
                    
                    this.allload=true
                    setTimeout(()=>{
                        this.$refs.ue.setUEContent(res.data.articleContent)
                    },500)
                }
            })
        },
        editArticle(){
            this.form.articleContent=this.$refs.ue.getUEContent()
            _api.post(apiurl.newsedit,this.form).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("编辑成功！");
                    this.$router.push("/information/index")
                }
            })
        },
        getClass(){
            _api.get(apiurl.list,{}).then(res=>{
                //console.log(res)
                this.classlist=res.data
            })
        },
        onEditorBlur(){//失去焦点事件
        },
        onEditorFocus(){//获得焦点事件
        },
        onEditorChange(){//内容改变事件
        }
    }
}
</script>
<style >
.el-form-item__content{ height: auto!important; line-height: 100%!important;}
#myeditor{ height: 550px!important;}
</style>
<style scoped>

.quillEditor{
        height: 300px;
    }
</style>